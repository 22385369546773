@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    text-align: left;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}