@media all and (min-width: 480px) {
  .MyRates {
    padding: 60px 0;
    text-align: left;
  }

  .MyRates form {
    margin: 0 auto;
    max-width: 520px;
  }

  .dd {
    max-width: 60px;
  }

  .cell {
    width: 150px;
  }

  .rate {
    width: 120px;
  }
}